import Close from "../images/incorrect.svg";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import logger from "../helpers/logger";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { apiEndpoint } from "./dashboard";
import { useCallback, useEffect, useState } from "react";

export const PushOrderModal = ({
  data,
  email,
  orderId,
  refresh,
  dismissModal,
}: {
  data: { [key: string]: any } | null;
  refresh: () => void;
  dismissModal: () => void;
  email: string;
  orderId: number;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [includeShaker, setIncludeShaker] = useState(false);
  const handlePushMealOrder = () => {
    const requests = [
      () =>
        axios({
          method: "post",
          url: `${apiEndpoint}admin/patient/${email}/mealOrder/${orderId}`,
          data: { includeShaker },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];
    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Push meal order", response1);
        alert("Meal Order has been placed");
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: "Patient meal order was pushed to shopify",
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#e3e6df99",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          minHeight: "60%",
          backgroundColor: "white",
          margin: "auto",
          marginTop: "5%",
          borderRadius: 8,
          padding: 24,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 12,
          }}
        >
          <img
            src={Close}
            alt=""
            onClick={() => {
              dismissModal();
            }}
            style={{ maxHeight: 48, maxWidth: 48 }}
          />
        </div>
        <div>
          <h1>Push this order through to Shopify?</h1>
          <p>
            This will place the order in shopify without triggering any payment.
            Please make sure you have handled any payment in stripe before
            pushing the order through.
          </p>
          <p>Would you like to add a shaker bottle to the order?</p>
          <label>
            <input
              type="checkbox"
              checked={includeShaker}
              onChange={() => setIncludeShaker(!includeShaker)}
            />
            Include shaker bottle
          </label>

          <div
            style={{
              alignContent: "center",
              textAlign: "center",
              marginTop: 24,
            }}
          >
            <p>Are you sure you want to continue?</p>
            <button
              className="cta cancelUserButton"
              onClick={() => {
                handlePushMealOrder();
              }}
            >
              {"Place this order"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
