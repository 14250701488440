export enum IdVerificationStatus {
  INCOMPLETE = "INCOMPLETE",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

export enum FullBodySelfieStatus {
  READY = "READY",
  FAILED = "FAILED",
  SUBMITTED = "SUBMITTED",
}

export enum TipDeclinedConsultationReasonCodes {
  PHOTO_QUALITY = "PHOTO_QUALITY",
  PHOTO_LIGHTING = "PHOTO_LIGHTING",
  PHOTO_CLOTHING = "PHOTO_CLOTHING",
  PHOTO_CRITERIA = "PHOTO_CRITERIA",
  PHOTO_FACE = "PHOTO_FACE",
  IDENTITY_NOT_CONFIRMED = "IDENTITY_NOT_CONFIRMED",
  NOT_SUITABLE = "NOT_SUITABLE",
}
