import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LaunchIcon from "@mui/icons-material/Launch";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { RowBottomContainer } from "./rowBottomContainer";
import { isStaging } from "../prescription-payments";
import { format, parseISO } from "date-fns";
import { CreateOrderModal } from "./createOrderModal";
import axios from "axios";
import { auth } from "../../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import { TipDeclinedConsultationReasonCodes } from "../../types/types"
export const apiEndpoint = process.env.REACT_APP_GLP_URL;
const appApiEndpoint = process.env.REACT_APP_API_FIREBASE_URL

const darkGreen = "#2C4A43";
const greenTint = "#e0f9f2";
const green = "#6CE4BF";
const blue = "#5DA7EB";
const yellow = "#CCB853";
const purple = "#99A7EB";
const red = "#EE8868";
const redTint = "#F9DBD1";
// const purpleTint = "#EAEDFB";
// const blueTint = "#DEEDFB";
const greyTint = "#E9ECEC";

const getStatusStyling = (status: string) => {
  const style: { color?: string; fontWeight?: string } = {
    fontWeight: "bold",
  };
  if (status === "PAID") {
    style.color = green;
  } else if (status === "UPCOMING") {
    style.color = yellow;
  } else if (status === "FAILED") {
    style.color = red;
  } else if (status === "REFUNDED") {
    style.color = purple;
  }
  return style;
};

const getRowStyle = (row: any) => {
  const style: { backgroundColor?: string } = {};
  if (
    row.prescriptionPayment.MedicationReviewId &&
    row.prescriptionPayment.Prescriptions &&
    row.prescriptionPayment.Prescriptions.length > 0
  ) {
    style.backgroundColor = greenTint;
  } else if (
    !row.prescriptionPayment.MedicationReviewId &&
    row.prescriptionPayment.chargeId &&
    row.prescriptionPayment.status === "PAID"
  ) {
    // style.backgroundColor = redTint;
  } else if (row.prescriptionPayment.status === "FAILED") {
    style.backgroundColor = redTint;
  } else if (row.prescriptionPayment.status === "UPCOMING") {
    // style.backgroundColor = greyTint;
  } else if (row.prescriptionPayment.status === "REFUNDED") {
    style.backgroundColor = greyTint;
  }
  return style;
};

const FbsRejectionOptions = Object.values(TipDeclinedConsultationReasonCodes).map((reason, index) => {
    return (<option key={`declineReason-${index}`} value={reason}>
      {reason}
    </option>)
  })

FbsRejectionOptions.unshift(<option key={`declineReason-unsleected`} value={'none'}>
----------------
</option>)

const AnswersAccordion = ({
  children,
  title = "Medication Review Answers",
}: {
  children: any;
  title?: string;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell>{title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell colSpan={2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const formatDate = (date: string) => {
  if (!date) {
    return null;
  }
  return format(parseISO(date), "hh:mmaaa do MMM yy");
};

const getCurrencySymbol = (currency: string) => {
  if (currency === "gbp") {
    return "£";
  } else if (currency === "eur") {
    return "€";
  } else {
    return "";
  }
};

export const Row = (props: {
  row: any;
  email: string | null;
  defaultPharmacyId: number;
  handleResetTask: () => void;
  handleUpdateNewDosage: (updatedNewDosage: string) => void;
  handleCancelOrder: (
    pharmacyId: number,
    consultationId: string,
    orderId?: string
  ) => void;
  refresh: () => void;
}) => {
  const {
    row,
    email,
    defaultPharmacyId,
    handleResetTask,
    handleUpdateNewDosage,
    handleCancelOrder,
    refresh,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<{
    email: string | null;
    pharmacyId: number;
    medication: string;
    prescriptionPayment: any;
    medicationReview?: any;
    initialOrder?: boolean;
    gpConsent?: {}
  } | null>(null);

  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  const [trackingNumber, setTrackingNumer] = useState<undefined | string>();
  const [comments, setComments] = useState<
   {[key: string]: { comment: string; author: string }[]}
  >({});
  const [newComment, setNewComment] = useState("");

  const [rejectReason, setRejectReason] = useState<TipDeclinedConsultationReasonCodes | "">("");

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const handleTrackOrder = (
    pharmacyId: number,
    orderId?: string,
    getComments?: boolean
  ) => {
    axios({
      method: "GET",
      url: `${apiEndpoint}admin/v1/pharmacy/${pharmacyId}/order/${orderId}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async (response: any) => {
        if (response.data) {
          return response.data;
        } else {
          console.log(response);
          throw new Error("No data");
        }
      })
      .then((data) => {
        if (getComments && data.comments && orderId) {
          data.comments = data.comments.replaceAll('\n',' ');
          const commentObject = data.comments
            ? JSON.parse(data.comments)
            : undefined;
          const commentArray = commentObject.map((c: { comment: string; comment_by: string }) => {
            return {
              comment: c.comment,
              author: c.comment_by,
            };
          })
          setComments({...comments,
            [orderId]: commentArray,
          });
        } else {
          if (data.trackingUrl) {
            setTrackingNumer(data.trackingId);
            // navigate to tracking url
            window.open(data?.trackingUrl, "_blank");
          } else {
            alert(`No tracking url found.`);
          }
        }
      })
      .catch((error: any) => {
        console.log(error)
        alert(`Error getting tracking information.`);
      });
  };

  const addNewComment = (
    orderId: string,
    pharmacyId: number,
    comment: string,
  ) => {
    axios({
      method: "POST",
      url: `${apiEndpoint}admin/v1/pharmacy/${pharmacyId}/order/${orderId}/comment`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        comment
      }
    })
      .then(async (response: any) => {
        await handleTrackOrder(pharmacyId, orderId, true)
        await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_FIREBASE_URL}admin/prescription/${orderId}`,
          headers: {
            "x-api-key": `${process.env.REACT_APP_APP_API_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            handler: user?.displayName
          }
        })
      })
      .catch(() => {
        alert(`Error getting posting the comment.`);
      });
  };

  const handleRejectFBS = async (
    rejectReason: TipDeclinedConsultationReasonCodes,
    pharmacyId: number,
    pharmacyOrderId?: string,
  ) => {
    try {
      if (!pharmacyOrderId) {
        alert(`You should never see this alert... talk to a dev`,);
      }
      
      await axios({
        method: "POST",
        url: `${appApiEndpoint}admin/pharmacy/${pharmacyId}/${pharmacyOrderId}/rejectFBS`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          declinedConsultationReasonCode: rejectReason,
        }
      })
    } catch (error) {
      console.log(error)
      alert(`Error rejecting the order or resetting the FBS task.`);
    }
  }

  return (
    <React.Fragment>
      <TableRow
        style={getRowStyle(row)}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.prescriptionPayment.paymentDate}</TableCell>
        <TableCell>{row.prescriptionPayment.product}</TableCell>
        <TableCell style={getStatusStyling(row.prescriptionPayment.status)}>
          {row.prescriptionPayment.status}
        </TableCell>
        <TableCell align="center">
          {row.medicationReview ? (
            <DoneAllIcon style={{ color: blue }} />
          ) : (
            <QuestionMarkIcon style={{ color: red }} />
          )}
        </TableCell>
        <TableCell align="center">
          {row.prescriptionPayment.Prescriptions &&
          row.prescriptionPayment.Prescriptions.length > 0 ? (
            <ReceiptLongIcon style={{ color: green }} />
          ) : (
            <HourglassEmptyIcon style={{ color: blue }} />
          )}
        </TableCell>
        <TableCell>
          {row.prescriptionPayment.chargeId && (
            <IconButton
              onClick={() => {
                window.open(
                  `https://tryhabitual-${
                    isStaging ? "staging-shop" : ""
                  }sp.admin.rechargeapps.com/merchant/orders/charges/${
                    row.prescriptionPayment.chargeId
                  }`,
                  "_blank"
                );
              }}
            >
              <LaunchIcon style={{ color: darkGreen }} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #dbdada",
                  margin: 6,
                }}
              >
                <h2>Payment Details</h2>
                <div>
                  {row.prescriptionPayment.totalAmount && (
                    <p>
                      Amount:{" "}
                      {getCurrencySymbol(row.prescriptionPayment.currency)}
                      {row.prescriptionPayment.totalAmount}
                    </p>
                  )}
                  {row.prescriptionPayment.chargeId && (
                    <p>
                      Charge:{" "}
                      <a
                        href={`https://tryhabitual-${
                          isStaging ? "staging-shop" : ""
                        }sp.admin.rechargeapps.com/merchant/orders/charges/${
                          row.prescriptionPayment.chargeId
                        }`}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        {row.prescriptionPayment.chargeId}
                      </a>
                    </p>
                  )}
                  {row.prescriptionPayment.createdAt && (
                    <p>Created at: {row.prescriptionPayment.createdAt}</p>
                  )}
                </div>
              </div>
              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    margin: 6,
                  }}
                >
                  <h2>
                    {row.initialConsultation
                      ? "Initial Consultation"
                      : "Medication Review"}
                  </h2>
                  {row.initialConsultation ? (
                    <div>
                      <div>
                        <p>
                          Date:{" "}
                          {row?.initialConsultation?.createdAt &&
                            formatDate(row?.initialConsultation?.createdAt)}
                        </p>
                        <p>BMI: {row?.initialConsultation?.bmi}</p>
                        <p>
                          Upcoming dosage: {row?.initialConsultation?.newDosage}
                        </p>
                      </div>
                      <AnswersAccordion title="Initial Consultation Answers">
                        {Object.entries(row?.initialConsultation?.answers).map(
                          ([key, value]: any) => {
                            return (
                              <div key={`review-${key}`}>
                                <h4>{value.question}</h4> - {value.answer}
                              </div>
                            );
                          }
                        )}
                      </AnswersAccordion>
                      <RowBottomContainer
                        handleResetTask={handleResetTask}
                        handleUpdateNewDosage={(updatedNewDosage: string) =>
                          handleUpdateNewDosage(updatedNewDosage)
                        }
                        row={row}
                      />
                    </div>
                  ) : !row.medicationReview ? (
                    <p>No review in the last 7 days.</p>
                  ) : (
                    <div>
                      <div>
                        <p>
                          Review Date:{" "}
                          {row?.medicationReview.createdAt &&
                            formatDate(row?.medicationReview.createdAt)}
                        </p>
                        <p>BMI: {row?.medicationReview?.bmi}</p>
                        <p>
                          Upcoming dosage: {row?.medicationReview?.newDosage}
                        </p>
                        <p>
                          {" "}
                          Potential cancellation:{" "}
                          {row?.medicationReview?.potentialCancellation
                            ? "yes"
                            : "no"}
                        </p>
                      </div>
                      <AnswersAccordion>
                        {Object.entries(
                          row?.medicationReview?.medicationReviewAnswers
                        ).map(([key, value]: any) => {
                          return (
                            <div key={`review-${key}`}>
                              <h4>{value.question}</h4> - {value.answer}
                            </div>
                          );
                        })}
                      </AnswersAccordion>
                      <RowBottomContainer
                        handleResetTask={handleResetTask}
                        handleUpdateNewDosage={(updatedNewDosage: string) =>
                          handleUpdateNewDosage(updatedNewDosage)
                        }
                        row={row}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    margin: 6,
                    marginLeft: 18,
                  }}
                >
                  <h2>Prescriptions</h2>
                  {(!row?.prescriptionPayment?.Prescriptions ||
                    row?.prescriptionPayment?.Prescriptions.length === 0) && (
                    <Fragment>
                      <p>No prescriptions are associated with this payment.</p>
                      {row?.medicationReview?.newDosage &&
                        row?.medicationReview &&
                        row?.prescriptionPayment &&
                        ["PAID", "PART_REFUNDED"].includes(
                          row?.prescriptionPayment.status
                        ) && (
                          <button
                            style={{ margin: 6 }}
                            className="cta"
                            onClick={() => {
                              console.log(email);
                              setModalData({
                                email,
                                medication: row?.medicationReview?.newDosage,
                                pharmacyId: defaultPharmacyId,
                                medicationReview: row?.medicationReview,
                                prescriptionPayment: row?.prescriptionPayment,
                                initialOrder: row?.key === 0,
                                gpConsent: row?.gpConsent
                              });
                              setIsModalVisible(true);
                            }}
                          >
                            Resubmit order
                          </button>
                        )}
                    </Fragment>
                  )}
                  {row?.prescriptionPayment?.Prescriptions?.map(
                    (
                      prescription: {
                        pharmacyConsultationId: string;
                        pharmacyOrderId?: string;
                        status: string;
                        mappedStatus: string;
                        pharmacyId: number;
                        pharmacyName: string;
                        createdAt: string;
                        updatedAt: string;
                        PrescriptionItems: [
                          { medication: string; quantity: number }
                        ];
                      },
                      index: number
                    ) => {
                      return (
                        <div key={`prescription-${index}`}>
                          <div style={{ border: "1px solid #dbdada" }} />
                          <h4>
                            Pharmacy consultation ID:{" "}
                            {prescription?.pharmacyConsultationId}
                          </h4>
                          <h4>
                            Pharmacy order ID: {prescription?.pharmacyOrderId}
                          </h4>
                          <p>Created: {formatDate(prescription?.createdAt)}</p>
                          <p>Updated: {formatDate(prescription?.updatedAt)}</p>
                          <p>
                            Pharmacy:{" "}
                            {prescription.pharmacyName ||
                              prescription.pharmacyId}
                          </p>
                          <p>Pharmacy status: {prescription?.status}</p>
                          {trackingNumber && (
                            <p>Tracking Number: {trackingNumber}</p>
                          )}
                          <p>
                            Habitual app status: {prescription?.mappedStatus}
                          </p>
                          <h4>Prescription Items</h4>
                          {prescription.PrescriptionItems.map(
                            (item, itemIndex) => {
                              return (
                                <div
                                  key={`$prescription-${index}-item-${itemIndex}`}
                                  style={{ marginBottom: 10 }}
                                >
                                  <div>Medication: {item.medication}</div>
                                  Quantity: {item.quantity}
                                </div>
                              );
                            }
                          )}
                          {prescription.pharmacyId === 2 && prescription.pharmacyOrderId && (
                            <>
                              <h4>C4U Comments</h4>
                              {comments &&
                                comments[prescription.pharmacyOrderId] &&
                                comments[prescription.pharmacyOrderId].length > 0 &&
                                comments[prescription.pharmacyOrderId].map(
                                  (
                                    comment: {
                                      comment: string;
                                      author: string;
                                    },
                                    commentIndex: number
                                  ) => {
                                    return (
                                      <div
                                        key={`$comment-${index}-item-${commentIndex}`}
                                        style={{ marginBottom: 10 }}
                                      >
                                        <div>Comment: {comment.comment}</div>
                                        Author: {comment.author}
                                      </div>
                                    );
                                  }
                                )}
                                {(!comments[prescription.pharmacyOrderId] ||
                                  comments[prescription.pharmacyOrderId].length === 0) && (
                                  <p>No comments yet</p>
                                )}
                                <h3>Add a comment</h3>
                                <textarea
                                  style={{ height: 100 }}
                                  className="newNoteTextInput"
                                  onChange={(e) => setNewComment(e.target.value)}
                                />
                                <button
                                  className="cta newNoteButton"
                                  disabled={newComment.length < 3}
                                  onClick={() => {
                                    if (prescription.pharmacyOrderId) {
                                    addNewComment( prescription.pharmacyOrderId, prescription.pharmacyId, newComment);
                                  }}}
                                >
                                  {"Send Comment"}
                                </button>
                            </>
                          )}
                          <div>
                            {prescription &&
                              prescription.mappedStatus !== "SENT" &&
                              prescription.mappedStatus !== "REVIEWED" &&
                              prescription.mappedStatus !== "PRESCRIBED" &&
                              prescription.mappedStatus !== "DISPENSED" &&
                              row?.medicationReview?.newDosage &&
                              row.prescriptionPayment.customerId && (
                                <button
                                  style={{ margin: 6 }}
                                  className="cta"
                                  onClick={() => {
                                    console.log(email);
                                    setModalData({
                                      email,
                                      medication:
                                        row?.medicationReview?.newDosage,
                                      pharmacyId: prescription.pharmacyId,
                                      medicationReview: row?.medicationReview,
                                      prescriptionPayment:
                                        row?.prescriptionPayment,
                                      gpConsent: row?.gpConsent
                                    });
                                    setIsModalVisible(true);
                                  }}
                                >
                                  Create order
                                </button>
                              )}
                            {prescription &&
                              prescription.mappedStatus !== "SENT" &&
                              prescription.mappedStatus !== "REVIEWED" &&
                              prescription.mappedStatus !== "PRESCRIBED" &&
                              prescription.mappedStatus !== "DISPENSED" &&
                              row?.initialConsultation?.newDosage &&
                              row.prescriptionPayment.customerId && (
                                <button
                                  style={{ margin: 6 }}
                                  className="cta"
                                  onClick={() => {
                                    console.log(email);
                                    setModalData({
                                      email,
                                      medication:
                                        row?.initialConsultation?.newDosage,
                                      pharmacyId: prescription.pharmacyId,
                                      medicationReview:
                                        row?.initialConsultation,
                                      prescriptionPayment:
                                        row?.prescriptionPayment,
                                      initialOrder: true,
                                      gpConsent: row?.gpConsent
                                    });
                                    setIsModalVisible(true);
                                  }}
                                >
                                  Create order
                                </button>
                              )}
                            {prescription &&
                              prescription.pharmacyId === 2 &&
                              prescription.pharmacyOrderId && (
                                <button
                                  style={{ margin: 6 }}
                                  className="cta"
                                  onClick={() => {
                                    handleTrackOrder(
                                      prescription.pharmacyId,
                                      prescription.pharmacyOrderId,
                                      true
                                    );
                                  }}
                                >
                                  Refresh comments
                                </button>
                              )}
                           {prescription &&
                              prescription.mappedStatus !== "DISPENSED" &&
                              prescription.mappedStatus !== "SHIPPED" &&
                              prescription.mappedStatus !== "CANCELLED" && 
                              prescription.pharmacyId === 2 &&
                              prescription.pharmacyOrderId &&
                              (
                                <div>
                                  <select
                                    onChange={(e) => {
                                      setRejectReason(e.target.value as TipDeclinedConsultationReasonCodes);
                                    }}
                                    defaultValue={'none'}
                                  >
                                    {FbsRejectionOptions}
                                  </select>
                                  <button
                                    style={{ margin: 6 }}
                                    className="cta cancelUserButton"
                                    onClick={() =>
                                      handleRejectFBS(
                                        rejectReason as TipDeclinedConsultationReasonCodes,
                                        prescription.pharmacyId,
                                        prescription.pharmacyOrderId
                                      )
                                    }
                                  >
                                    Reject FBS and Cancel
                                  </button>
                                </div>
                              )}
                            {prescription &&
                              prescription.mappedStatus !== "DISPENSED" &&
                              prescription.mappedStatus !== "SHIPPED" &&
                              prescription.mappedStatus !== "CANCELLED" && (
                                <button
                                  style={{ margin: 6 }}
                                  className="cta cancelUserButton"
                                  onClick={() =>
                                    handleCancelOrder(
                                      prescription.pharmacyId,
                                      prescription.pharmacyConsultationId,
                                      prescription?.pharmacyOrderId
                                    )
                                  }
                                >
                                  Cancel
                                </button>
                              )}
                            {prescription?.pharmacyOrderId &&
                              typeof prescription.pharmacyOrderId !==
                                "undefined" &&
                              prescription.mappedStatus === "SHIPPED" && (
                                <button
                                  style={{ margin: 6 }}
                                  className="cta trackOrderButton"
                                  onClick={() =>
                                    handleTrackOrder(
                                      prescription.pharmacyId,
                                      prescription.pharmacyOrderId
                                    )
                                  }
                                >
                                  Track
                                </button>
                              )}
                          </div>
                        </div>
                      );
                    }
                  )}
                  {isModalVisible && modalData && (
                    <CreateOrderModal
                      dismissModal={() => {
                        setModalData(null);
                        setIsModalVisible(false);
                      }}
                      data={modalData}
                      refresh={refresh}
                    />
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
