import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import logger from "../helpers/logger";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { ProgrammeType } from "../helpers/programmeHelper";

export const ChangeStatus = ({
  data,
  refresh,
}: {
  data: {
    firstName: string;
    status: string;
    email: string;
    hubspotId: string;
    patientType: ProgrammeType;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { firstName, status, email, hubspotId } = data || {};
  const [currentStatus, setCurrentStatus] = useState(status);
  const [setupSubscriptions, setSetupSubscriptions] = useState(false);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeStatus = () => {
    const requests = [];
    if (setupSubscriptions) {
      requests.push(() =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/setToActive/`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
      );
    } else {
      requests.push(() =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/v2/patient/`,
          data: { email: email, status: currentStatus, hubspotId },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
      );
    }

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Status Changed:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Status was changed from ${status} to ${currentStatus}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };
  return (
    <Fragment>
      {status ? (
        <div className={"changeStatus cascade centre"}>
          <label>{`${firstName}'s current status is:`}</label>
          <div>
            <select
              onChange={(e) => {
                if (
                  currentStatus === "FREE_TRIAL" &&
                  e.target.value === "ACTIVE"
                ) {
                  if (data?.patientType !== ProgrammeType.MOMENTA) {
                    alert(
                      "This will create subscriptions in Stripe, do you want to do that?"
                    );
                    setSetupSubscriptions(true);
                  }
                  setCurrentStatus(e.target.value);
                } else if (
                  currentStatus === "ACTIVE" &&
                  e.target.value === "FREE_TRIAL"
                ) {
                  alert("Please use reset to welcome guide");
                } else {
                  setSetupSubscriptions(false);
                  setCurrentStatus(e.target.value);
                }
              }}
              defaultValue={currentStatus}
            >
              <option value={"FREE_TRIAL"}>{"FREE_TRIAL"}</option>
              <option value={"ONBOARDING"}>{"ONBOARDING"}</option>
              <option value={"ACTIVE"}>{"ACTIVE"}</option>
              <option value={"CHURNED"}>{"CHURNED"}</option>
            </select>
            <button className={"cta"} onClick={handleChangeStatus}>
              {"SAVE!"}
            </button>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
