import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import promiseAccumulator from "../helpers/promiseAccumulator";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, subDays } from "date-fns";
export const apiEndpoint = process.env.REACT_APP_PAYMENT_URL;

export const ReportingTools = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [dateFrom, setDateFrom] = useState(subDays(new Date(), 7));
  const [dateTo, setDateTo] = useState(new Date());
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setDateFrom(start);
    setDateTo(end);
  };

  const handleRequest = (glpStripe?: boolean) => {
    console.log("Requesting!");
    setLoading(true);
    const data = {
      dateFrom: format(dateFrom, "yyyy-MM-dd"),
      dateTo: format(dateTo, "yyyy-MM-dd"),
      glpStripe,
      email: user?.email,
    };

    const requests = [
      () =>
        axios({
          method: "POST",
          url: `${apiEndpoint}admin/patient/paymentReport`,
          headers: {
            "admin-key": `${process.env.REACT_APP_PAYMENT_API_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
          data,
        }),
    ];
    promiseAccumulator(requests)
      .then(async ([response1]: any) => {
        setLoading(false);
        if (response1.status === 201) {
          alert(`Request submitted, data will be sent to ${user?.email} soon`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (!user || !user.email) {
    navigate("/");
    return null;
  } else {
    return (
      <div className={"dash"}>
        <Sidebar activeTab="reporting" />
        <div className={"reportTools"}>
          {loading && (
            <div className="loadingOverlay">
              <div>Loading...</div>
            </div>
          )}

          <div className={"reportCard"}>
            <h1>Customer spend report</h1>
            <p>Please select a date range:</p>
            <p>
              This will generate a spend report for any customers created in
              Stripe during this range up until now
            </p>
            <DatePicker
              selected={dateFrom}
              onChange={onChange}
              startDate={dateFrom}
              endDate={dateTo}
              selectsRange
              inline
            />

            <button className={"cta"} onClick={() => handleRequest(false)}>
              Request TDR
            </button>
            <button className={"cta"} onClick={() => handleRequest(true)}>
              Request GLP
            </button>
          </div>
          <div className={"reportCard"}>
            <h1>Investor KPI report</h1>
            <p>Coming soon!</p>
          </div>
          <div className={"reportCard"}>
            <h1>Churn report</h1>
            <p>Coming soon!</p>
          </div>
          <div className={"reportCard"}>
            <h1>Average weight loss report</h1>
            <p>Coming soon!</p>
          </div>
        </div>
      </div>
    );
  }
};
