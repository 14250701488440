import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import logger from "../helpers/logger";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import {
  getPlanFromName,
  getPlanName,
  PaymentPlans,
} from "../helpers/programmeHelper";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import "./globals.css";
import promiseAccumulator from "../helpers/promiseAccumulator";

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

const getNames = () => {
  const options = [];

  for (const name of enumKeys(PaymentPlans)) {
    options.push(
      <option key={`nameoption-${name}`} value={PaymentPlans[name]}>
        {PaymentPlans[name]}
      </option>
    );
  }

  return options;
};

export const ChangePlan = ({
  data,
  refresh,
}: {
  data: {
    programmeStatus: { paymentPlan: string };
    email: string;
    hubspotId: string;
    status: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { programmeStatus, email } = data || {};
  const [currentPlanName, setCurrentPlanName] = useState(
    programmeStatus?.paymentPlan
      ? getPlanName(programmeStatus.paymentPlan)
      : "unknown"
  );

  const [currentPaymentPlan, setCurrentPaymentPlan] = useState(
    programmeStatus?.paymentPlan
  );

  useEffect(() => {
    const plan = getPlanFromName(currentPlanName);

    setCurrentPaymentPlan(plan);
  }, [currentPlanName]);

  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeProgramme = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/paymentPlan`,
          data: {
            selectedPlanId: currentPaymentPlan,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Changed Programme CMS ID:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Payment plan was updated from ${programmeStatus?.paymentPlan} to ${currentPaymentPlan}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        alert(
          "Patient was not updated - please check if they have an active subscription"
        );
        logger.error(error);
      });
  };

  const disabled = programmeStatus?.paymentPlan === currentPaymentPlan;

  return (
    <Fragment>
      <div>
        <label>{`Change payment plan and content to: `}</label>
        <select
          onChange={(e) => {
            setCurrentPlanName(e.target.value);
          }}
          defaultValue={currentPlanName}
        >
          {getNames()}
        </select>
        <button
          className={"cta"}
          onClick={handleChangeProgramme}
          disabled={disabled}
          style={disabled ? { cursor: "not-allowed" } : {}}
        >
          {"SAVE!"}
        </button>
      </div>
    </Fragment>
  );
};
