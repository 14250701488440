import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import promiseAccumulator from "../helpers/promiseAccumulator";
import axios from "axios";
import { StockItem } from "./stock-item";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const StockLevels = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [stockData, setStockData] = useState<
    {
      barcodeNumber: string;
      quantity: number;
      productName: string;
      readyToOrderQuantity: number;
    }[]
  >([]);

  useEffect(() => {
    if (authToken) {
    setLoading(true);
    const requests = [
      () =>
        axios({
          method: "get",
          url: `${apiEndpoint}admin/mealProducts`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
        }),
    ];
    promiseAccumulator(requests)
      .then(async ([response1]: any) => {
        if (response1.data) {
          return response1.data;
        }
      })
      .then((data) => {
        setStockData(data.formattedStock);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }}, [authToken]);

  const submitStockChange = useCallback((barcodeNumber, quantity) => {
    setLoading(true);
    const requests = [
      () =>
        axios({
          method: "post",
          url: `${apiEndpoint}admin/mealProducts`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
          data: { barcodeNumber, quantity },
        }),
    ];
    promiseAccumulator(requests)
      .then(async ([response1]: any) => {
        if (response1.data) {
          return response1.data;
        }
      })
      .then((data) => {
        setStockData(data.formattedStock);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user || !user.email) {
    navigate("/");
    return null;
  } else {
    return (
      <div className={"dash"}>
        <Sidebar activeTab="stockLevels" />
        <div>
          {loading ||
            (!stockData && (
              <div className="loadingOverlay">
                <div>Loading...</div>
              </div>
            ))}
          <div className="viewing">
            <label className="stockLevelsLabel">Stock Levels</label>
          </div>
          <div className="stockDataItemContainer">
            <span className="stockItemName">Product Name</span>
            <span className="stockItemName">Number Available</span>
            <span className="stockItemName">Number In Pending Orders</span>
            <span className="stockItemName">Number In Stock</span>
            <span className="stockItemName">Barcode Number</span>
          </div>
          {stockData
            .sort((a: { productName: string }, b: { productName: string }) => {
              return a.productName.localeCompare(b.productName);
            })
            .map((stockItem) => {
              return (
                <StockItem
                  callback={(barcodeNumber, quantity) => {
                    submitStockChange(barcodeNumber, quantity);
                  }}
                  key={stockItem.barcodeNumber}
                  item={stockItem}
                />
              );
            })}
        </div>
      </div>
    );
  }
};
