import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { Dashboard } from "./components/dashboard";
import { MealOrdersView } from "./components/meal-orders";
import { NotFound } from "./components/not-found";
import { StockLevels } from "./components/stock-levels";
import { ReportingTools } from "./components/reporting-tools";
import { TestingTools } from "./components/testing-tools";
import { NewMomentaPatientForm } from "./components/new-momenta-patient";
import { PharmacyTools } from "./components/pharmacy-tools";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="prescriptionPayments"
        element={<Dashboard paymentsView={true} />}
      />
      <Route path="/pharmacy" element={<PharmacyTools />} />
      <Route path="/stockLevels" element={<StockLevels />} />
      <Route path="/mealOrders" element={<MealOrdersView />} />
      <Route path="/reporting" element={<ReportingTools />} />
      <Route path="/testing" element={<TestingTools />} />
      <Route path="/newMomentaPatient" element={<NewMomentaPatientForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
