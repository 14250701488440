import { useCallback, useState } from "react";

export const StockItem = ({
  item,
  callback,
}: {
  item: {
    barcodeNumber: string;
    quantity: number;
    productName: string;
    readyToOrderQuantity: number;
  };
  callback: (barcodeNumber: string, quantity: number) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(
    item.quantity + item.readyToOrderQuantity
  );
  const saveChanges = useCallback(() => {
    callback(item.barcodeNumber, editedValue);
    setIsEditing(false);
  }, [callback, editedValue, item.barcodeNumber]);

  return (
    <div className="stockDataItemContainer">
      <span className="stockItemName">{item.productName}</span>
      <span className="stockItemName">{item.quantity}</span>
      <span className="stockItemName">{item.readyToOrderQuantity}</span>

      {isEditing ? (
        <input
          className="stockQuantityInput"
          type={"number"}
          value={editedValue}
          onChange={(e) => {
            setEditedValue(Number(e.target.value));
          }}
        />
      ) : (
        <span className="stockItemName">
          {item.quantity + item.readyToOrderQuantity}
        </span>
      )}
      <span className="stockItemName">{item.barcodeNumber}</span>
      <button
        className="cta"
        onClick={() => {
          if (isEditing) {
            saveChanges();
          } else {
            setIsEditing(true);
          }
        }}
      >
        {isEditing ? "Save" : "Edit"}
      </button>
      {isEditing && (
        <button onClick={() => setIsEditing(false)} className="cta cancelCta">
          Cancel
        </button>
      )}
    </div>
  );
};
