import { useCallback, useEffect, useRef, useState } from "react";
import { Sidebar } from "./sidebar";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "../styles/ag-grid.css";
import "../styles/ag-theme-habitual.css";
import { addWeeks, format } from "date-fns";

export const apiEndpoint = process.env.REACT_APP_GLP_URL;

const newPatientsStart = 100;
const newPatientGrowthRate = 2;

interface Props {
  goBack: () => void;
}

type OrdersData = {
  orders: {
    weekTitle: string;
    startTime: number;
    endTime: number;
    dosages: {
      [sku: string]: {
        sku: string;
        medication: string;
        quantity: number;
        nextDose?: string;
        previousDose?: string;
      };
    };
  }[];
  request?: string;
};

type ForecastData = {
  weekStarting: string;
  newPatients: number;
  "025mg"?: number;
  "050mg"?: number;
  "1mg"?: number;
  "3mg"?: number;
  "7mg"?: number;
  "14mg"?: number;
};

type ActiveTab = "Ozempic" | "Rybelsus";

export const StockForecast = ({ goBack }: Props) => {
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [activeTab, setActiveTab] = useState<ActiveTab>("Ozempic");
  const [upcomingOrdersData, setUpcomingOrdersData] = useState<OrdersData>();
  const [forecastData, setForecastData] = useState<ForecastData[]>([]);
  const [jobId, setJobId] = useState<string | null>(null);

  const [inputParameterColumns] = useState([
    { field: "dose", editable: false },
    {
      field: "stock",
      headerName: "In stock",
      headerTooltip: "How many of this dose do we actually have in stock?",
    },
    {
      field: "churn",
      headerName: "Churn (%)",
      headerTooltip:
        "Percentage of patients estimated to churn from this dose and not reorder next month",
    },
    {
      field: "increase",
      headerName: "Increase (%)",
      headerTooltip:
        "Percentage of patients who will increase their dose next month",
      cellStyle: (params: any) => {
        if (["1.00mg", "14mg"].includes(params.data.dose)) {
          return { color: "#1D332E33", backgroundColor: "#f7f8fd" };
        }
      },
      editable: (params: any) => {
        return !["1.00mg", "14mg"].includes(params.data.dose);
      },
    },
    {
      field: "same",
      headerName: "Stay the same (%)",
      headerTooltip:
        "Percentage of patients who will opt to not increase their dose for their next order",
    },
    {
      field: "decrease",
      headerName: "Decrease (%)",
      headerTooltip:
        "Percentage of patients who will opt to decrease their dose for their next order",
      cellStyle: (params: any) => {
        if (["0.25mg", "3mg"].includes(params.data.dose)) {
          return { color: "#1D332E33", backgroundColor: "#f7f8fd" };
        }
      },
      editable: (params: any) => {
        return !["0.25mg", "3mg"].includes(params.data.dose);
      },
    },
    // {
    //   field: "pause",
    //   headerName: "Pause (%)",
    //   headerTooltip: "Percentage of patients who will skip an order",
    //   editable: false,
    // },
    {
      field: "total",
      headerName: "Total (%)",
      headerTooltip: "Sum of the other parameters",
      editable: false,
      cellStyle: (params: any) => {
        if (params.value !== 100) {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value === 100) {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
  ]);

  const [input2Columns] = useState([
    {
      field: "newPatients",
      headerTooltip: "Number of new patients predicted this week",
    },
    {
      field: "growthRate",
      headerName: "Weekly growth rate (%)",
      headerTooltip: "Week on week growth rate percentage",
    },
  ]);

  const [input2Parameters, setInput2Parameters] = useState({
    newPatients: newPatientsStart,
    growthRate: newPatientGrowthRate,
  });

  const [inputOzempicParameters, setInputOzempicParameters] = useState([
    {
      dose: "0.25mg",
      stock: 250,
      churn: 11,
      increase: 80,
      same: 9,
      decrease: 0,
      pause: 0,
      total: 100,
    },
    {
      dose: "0.50mg",
      stock: 500,
      churn: 18,
      increase: 0,
      same: 82,
      decrease: 0,
      pause: 0,
      total: 100,
    },
    {
      dose: "1.00mg",
      stock: 1000,
      churn: 11,
      increase: 0,
      same: 89,
      decrease: 0,
      pause: 0,
      total: 100,
    },
  ]);

  const [inputRybelsusParameters, setInputRybelsusParameters] = useState([
    {
      dose: "3mg",
      stock: 500,
      churn: 11,
      increase: 82,
      same: 7,
      decrease: 0,
      pause: 0,
      total: 100,
    },
    {
      dose: "7mg",
      stock: 1000,
      churn: 18,
      increase: 64,
      same: 16,
      decrease: 1,
      pause: 0,
      total: 100,
    },
    {
      dose: "14mg",
      stock: 2000,
      churn: 11,
      increase: 0,
      same: 87,
      decrease: 2,
      pause: 0,
      total: 100,
    },
  ]);

  const [outputOzempicColumnDefs] = useState([
    { field: "weekStarting", width: 120 },
    { field: "newPatients", editable: true },
    {
      field: "025mg",
      headerName: "0.25mg",
      cellStyle: (params: any) => {
        const inStock = inputOzempicParameters[0].stock;
        if (params.value > inStock) {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value <= inStock) {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
    {
      field: "050mg",
      headerName: "0.5mg",
      cellStyle: (params: any) => {
        const inStock = inputOzempicParameters[1].stock;
        if (params.value > inStock) {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value <= inStock) {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
    {
      field: "1mg",
      cellStyle: (params: any) => {
        const inStock = inputOzempicParameters[2].stock;
        if (params.value > inStock) {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value <= inStock) {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
  ]);

  const [outputRybelsusColumnDefs] = useState([
    { field: "weekStarting", width: 120 },
    { field: "newPatients", editable: true },
    {
      field: "3mg",
      cellStyle: (params: any) => {
        const inStock = inputRybelsusParameters[0].stock;
        if (params.value > inStock) {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value <= inStock) {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
    {
      field: "7mg",
      cellStyle: (params: any) => {
        const inStock = inputRybelsusParameters[1].stock;
        if (params.value > inStock) {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value <= inStock) {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
    {
      field: "14mg",
      cellStyle: (params: any) => {
        const inStock = inputRybelsusParameters[2].stock;
        if (params.value > inStock) {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value <= inStock) {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
  ]);

  const inputGridColumnDefaults = {
    resizable: true,
    editable: true,
    width: 100,
  };
  const input2GridColumnDefaults = {
    resizable: true,
    editable: true,
    width: 100,
  };

  const outputGridColumnDefaults = {
    resizable: true,
    width: 100,
  };

  const sizeToFit = useCallback(() => {
    inputGridRef.current.api.sizeColumnsToFit();
    input2GridRef.current.api.sizeColumnsToFit();
    outputGridRef.current.api.sizeColumnsToFit();
  }, []);

  const getNewParamsRow = (event: any) => {
    const dose = event.data.dose;
    const stock = event.data.stock;
    const pause = parseInt(event.data.pause as unknown as string, 10);
    const same = parseInt(event.data.same as unknown as string, 10);
    const decrease = parseInt(event.data.decrease as unknown as string, 10);
    const increase = parseInt(event.data.increase as unknown as string, 10);
    const churn = parseInt(event.data.churn, 10);

    const total = pause + same + decrease + increase + churn;
    outputGridRef?.current?.api?.refreshCells({ force: true });
    return {
      dose,
      stock,
      pause,
      same,
      decrease,
      increase,
      churn,
      total,
    };
  };

  const cellUpdatedListener = useCallback(
    (event) => {
      if (event.colDef.field === "churn") {
        if (activeTab === "Ozempic") {
          const newParams = inputOzempicParameters.map((p, index) => {
            if (event.rowIndex === index) {
              return getNewParamsRow(event);
            } else {
              return p;
            }
          });
          setInputOzempicParameters(newParams);
        } else {
          const newParams = inputRybelsusParameters.map((p, index) => {
            if (event.rowIndex === index) {
              return getNewParamsRow(event);
            } else {
              return p;
            }
          });
          setInputRybelsusParameters(newParams);
        }
      } else if (event.colDef.field === "increase") {
        if (activeTab === "Ozempic") {
          const newParams = inputOzempicParameters.map((p, index) => {
            if (event.rowIndex === index) {
              return getNewParamsRow(event);
            } else {
              return p;
            }
          });
          setInputOzempicParameters(newParams);
        } else {
          setInputRybelsusParameters(
            inputRybelsusParameters.map((p, index) => {
              if (event.rowIndex === index) {
                return getNewParamsRow(event);
              } else {
                return p;
              }
            })
          );
        }
      } else if (event.colDef.field === "same") {
        if (activeTab === "Ozempic") {
          setInputOzempicParameters(
            inputOzempicParameters.map((p, index) => {
              if (event.rowIndex === index) {
                return getNewParamsRow(event);
              } else {
                return p;
              }
            })
          );
        } else {
          setInputRybelsusParameters(
            inputRybelsusParameters.map((p, index) => {
              if (event.rowIndex === index) {
                return getNewParamsRow(event);
              } else {
                return p;
              }
            })
          );
        }
      } else if (event.colDef.field === "decrease") {
        if (activeTab === "Ozempic") {
          setInputOzempicParameters(
            inputOzempicParameters.map((p, index) => {
              if (event.rowIndex === index) {
                return getNewParamsRow(event);
              } else {
                return p;
              }
            })
          );
        } else {
          setInputRybelsusParameters(
            inputRybelsusParameters.map((p, index) => {
              if (event.rowIndex === index) {
                return getNewParamsRow(event);
              } else {
                return p;
              }
            })
          );
        }
      } else if (event.colDef.field === "pause") {
        if (activeTab === "Ozempic") {
          setInputOzempicParameters(
            inputOzempicParameters.map((p, index) => {
              if (event.rowIndex === index) {
                return getNewParamsRow(event);
              } else {
                return p;
              }
            })
          );
        } else {
          setInputRybelsusParameters(
            inputRybelsusParameters.map((p, index) => {
              if (event.rowIndex === index) {
                return getNewParamsRow(event);
              } else {
                return p;
              }
            })
          );
        }
      } else if (event.colDef.field === "newPatients") {
        setInput2Parameters({
          ...input2Parameters,
          newPatients: parseInt(event.newValue, 10),
        });
      } else if (event.colDef.field === "growthRate") {
        setInput2Parameters({
          ...input2Parameters,
          growthRate: parseFloat(event.newValue),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const inputGridRef: any = useRef();
  const input2GridRef: any = useRef();
  const outputGridRef: any = useRef();

  const handleOrdersRequest = useCallback(() => {
    if (authToken) {
      setLoading(true);
      console.log("trigger job", activeTab);
      axios({
        method: "GET",
        url: `${apiEndpoint}admin/v1/stockForecast/${activeTab}`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(async (response: any) => {
          if (response.data) {
            setJobId(response.data.jobId);
          } else {
            throw new Error("No data");
          }
        })
        .catch((error) => {
          console.log("trigger error ", error);
          setLoading(false);
        });
    }
  }, [activeTab, authToken]);

  const handleCheckForCachedData = useCallback(() => {
    if (authToken && jobId) {
      console.log("polling", jobId);
      axios({
        method: "GET",
        url: `${apiEndpoint}admin/v1/cachedStockForecast/${jobId}`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(async (response: any) => {
          if (response.data) {
            return response.data.data;
          }
        })
        .then((data: OrdersData) => {
          if (data) {
            setUpcomingOrdersData({ ...data, request: activeTab });
            sizeToFit();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("polling error ", error);
          setLoading(false);
        });
    }
  }, [authToken, jobId]);

  useEffect(() => {
    if (!jobId) {
      handleOrdersRequest();
    }
  }, [handleOrdersRequest, jobId]);

  useEffect(() => {
    setJobId(null);
  }, [activeTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        jobId &&
        (!upcomingOrdersData || upcomingOrdersData.request !== activeTab)
      ) {
        handleCheckForCachedData();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [handleCheckForCachedData, jobId, upcomingOrdersData, activeTab]);

  const calculateUpcomingOrders = (upcomingOrdersData: OrdersData) => {
    const forecast: ForecastData[] = upcomingOrdersData?.orders?.map(
      (week, index) => {
        return {
          weekStarting: week.weekTitle,
          newPatients: Math.round(
            input2Parameters.newPatients *
              (1 + input2Parameters.growthRate / 100) ** index
          ),
          "025mg": week.dosages["2022-GBP-A1-WEIGHT-1-OZEMP025"]?.quantity,
          "050mg": week.dosages["2022-GBP-A1-WEIGHT-1-OZEMP050"]?.quantity,
          "1mg": week.dosages["2022-GBP-A1-WEIGHT-1-OZEMP100"]?.quantity,
          "3mg": week.dosages["2022-GBP-A1-WEIGHT-30-RYBELS3"]?.quantity,
          "7mg": week.dosages["2022-GBP-A1-WEIGHT-30-RYBELS7"]?.quantity,
          "14mg": week.dosages["2022-GBP-A1-WEIGHT-30-RYBELS14"]?.quantity,
        };
      }
    );
    const startDate = new Date(upcomingOrdersData?.orders[0]?.startTime);
    for (let i = 0; i < 48; i++) {
      forecast.push({
        weekStarting: `${format(addWeeks(startDate, i + 4), "do LLLL yy")}`,
        newPatients: Math.round(
          input2Parameters.newPatients *
            (1 + input2Parameters.growthRate / 100) ** (i + 5)
        ),
      });
    }
    forecast.forEach((week, index) => {
      if (activeTab === "Ozempic") {
        week["025mg"] = week["025mg"]
          ? week["025mg"] + week.newPatients
          : week.newPatients;
        if (index > 3) {
          // people who were on 0.25 and stay on it
          week["025mg"] +=
            (forecast[index - 4]["025mg"] as number) *
            inputOzempicParameters[0].same *
            0.01;
          // people who were on 0.25 last month and increase
          week["050mg"] =
            (forecast[index - 4]["025mg"] as number) *
            (inputOzempicParameters[0].increase * 0.01);
          // people who were on 0.5 last month and stay the same
          week["050mg"] +=
            (forecast[index - 4]["050mg"] as number) *
            inputOzempicParameters[0].same *
            0.01;
          // people who were on 1 last month and decrease
          week["050mg"] +=
            (forecast[index - 4]["1mg"] as number) *
            (inputOzempicParameters[2].decrease * 0.01);
          // people who were on 0.5 last month and increase
          week["1mg"] =
            (forecast[index - 4]["050mg"] as number) *
            (inputOzempicParameters[1].increase * 0.01);
          // people who were on 1 last month and stay the same
          week["1mg"] +=
            (forecast[index - 4]["1mg"] as number) *
            inputOzempicParameters[2].same *
            0.01;
          // people who were on 1 last month and decrease
          week["1mg"] -=
            (forecast[index - 4]["1mg"] as number) *
            (inputOzempicParameters[0].decrease * 0.01);
        }
        week["025mg"] = week["025mg"] && Math.round(week["025mg"]);
        week["050mg"] = week["050mg"] && Math.round(week["050mg"]);
        week["1mg"] = week["1mg"] && Math.round(week["1mg"]);
      } else if (activeTab === "Rybelsus") {
        week["3mg"] = week["3mg"]
          ? week["3mg"] + week.newPatients
          : week.newPatients;
        if (index > 3) {
          // people who were on 3 and stay on it
          week["3mg"] +=
            (forecast[index - 4]["3mg"] as number) *
            inputRybelsusParameters[0].same *
            0.01;
          // people who were on 0.25 last month and increase
          week["7mg"] =
            (forecast[index - 4]["3mg"] as number) *
            (inputRybelsusParameters[0].increase * 0.01);
          // people who were on 0.5 last month and stay the same
          week["7mg"] +=
            (forecast[index - 4]["7mg"] as number) *
            inputRybelsusParameters[0].same *
            0.01;
          // people who were on 1 last month and decrease
          week["7mg"] +=
            (forecast[index - 4]["14mg"] as number) *
            (inputRybelsusParameters[2].decrease * 0.01);
          // people who were on 0.5 last month and increase
          week["14mg"] =
            (forecast[index - 4]["7mg"] as number) *
            (inputRybelsusParameters[1].increase * 0.01);
          // people who were on 1 last month and stay the same
          week["14mg"] +=
            (forecast[index - 4]["14mg"] as number) *
            inputRybelsusParameters[2].same *
            0.01;
          // people who were on 1 last month and decrease
          week["14mg"] -=
            (forecast[index - 4]["14mg"] as number) *
            (inputRybelsusParameters[0].decrease * 0.01);
        }
        week["3mg"] = week["3mg"] && Math.round(week["3mg"]);
        week["7mg"] = week["7mg"] && Math.round(week["7mg"]);
        week["14mg"] = week["14mg"] && Math.round(week["14mg"]);
      }
    });

    setForecastData(forecast);
  };

  useEffect(() => {
    if (upcomingOrdersData) {
      calculateUpcomingOrders(upcomingOrdersData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    upcomingOrdersData,
    input2Parameters,
    activeTab,
    inputOzempicParameters,
    inputRybelsusParameters,
  ]);

  const handleCsvDownload = (tab: string) => {
    // if (data) {
    //     var blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    //     var url = URL.createObjectURL(blob);

    //     // Create a link to download it
    //     var pom = document.createElement("a");
    //     pom.href = url;
    //     pom.setAttribute(
    //       "download",
    //       `stockEstimate_${format(
    //         new Date(),
    //         "yyyy-MM-dd"
    //       )}_${new Date().getTime()}.csv`
    //     );
    //     pom.click();
    //   }
    outputGridRef.current.api.exportDataAsCsv({
      columnKeys: [
        "weekStarting",
        "3mg",
        "7mg",
        "14mg",
        "025mg",
        "050mg",
        "1mg",
      ],
      fileName: `stockEstimate_${tab}_${format(
        new Date(),
        "yyyy-MM-dd"
      )}_${new Date().getTime()}.csv`,
    });
  };

  return (
    <div className={"dash"}>
      <Sidebar activeTab="pharmacy" />
      {loading && (
        <div className="loadingOverlay">
          <div>Loading...</div>
        </div>
      )}
      <div className={"reportCard"}>
        <h1>Stock forecasting tool</h1>

        <div>
          <h2>Input parameters</h2>
          <h3>Medication type</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <button
              onClick={() => {
                setActiveTab("Ozempic");
              }}
              className={`tabNavButton ${
                activeTab === "Ozempic" && "activetabNavButton"
              }`}
              style={{ marginRight: 10, marginLeft: 10 }}
            >
              Ozempic
            </button>
            <button
              onClick={() => {
                setActiveTab("Rybelsus");
              }}
              className={`tabNavButton ${
                activeTab === "Rybelsus" && "activetabNavButton"
              }`}
              style={{ marginRight: 10, marginLeft: 10 }}
            >
              Rybelsus
            </button>
          </div>
          <h3>Subscription parameters</h3>
          <p>Please ensure each row adds up to 100%</p>
          <p>Static values, not based on live data... yet</p>
          <div
            className="ag-theme-alpine"
            style={{ width: "75%", height: 175, margin: 24 }}
          >
            <AgGridReact
              ref={inputGridRef}
              rowData={
                activeTab === "Ozempic"
                  ? inputOzempicParameters
                  : inputRybelsusParameters
              } // Row Data for Rows
              columnDefs={inputParameterColumns}
              defaultColDef={inputGridColumnDefaults}
              onCellEditingStopped={cellUpdatedListener}
              tooltipShowDelay={500}
            />
          </div>
          <h3>Expected growth</h3>
          <div
            className="ag-theme-alpine"
            style={{ width: "75%", height: 94, margin: 24 }}
          >
            <AgGridReact
              ref={input2GridRef}
              rowData={[input2Parameters]}
              columnDefs={input2Columns}
              defaultColDef={input2GridColumnDefaults}
              onCellEditingStopped={cellUpdatedListener}
              tooltipShowDelay={500}
            />
          </div>
        </div>
        <div>
          <h2>Estimated stock requirements</h2>
          <div
            className="ag-theme-alpine"
            style={{ width: "90%", height: 500, margin: 24 }}
          >
            <AgGridReact
              ref={outputGridRef}
              rowData={forecastData}
              defaultColDef={outputGridColumnDefaults}
              columnDefs={
                activeTab === "Ozempic"
                  ? outputOzempicColumnDefs
                  : outputRybelsusColumnDefs
              }
            />
          </div>
        </div>
        <button
          className={"cta"}
          onClick={() => {
            goBack();
          }}
        >
          Go back
        </button>
        <button
          className={"cta"}
          style={{ marginLeft: 12 }}
          onClick={() => {
            handleCsvDownload(activeTab);
          }}
        >
          Download
        </button>
      </div>
    </div>
  );
};
