import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import axios from "axios";
import { StockForecast } from "./stockForecast";
export const apiEndpoint = process.env.REACT_APP_GLP_URL;

export const PharmacyTools = () => {
  const [loading, setLoading] = useState(false);
  const [forecasting, setForecasting] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [orderId, setOrderId] = useState<string>();
  const [orderDetails, setOrderDetails] = useState<any>();

  const handleRequest = () => {
    setLoading(true);

    axios({
      method: "GET",
      url: `${apiEndpoint}admin/v1/truepill/order/${orderId}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async (response: any) => {
        if (response.data) {
          return response.data;
        } else {
          console.log(response);
          throw new Error("No data");
        }
      })
      .then((data) => {
        setOrderDetails(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleClearSearch = () => {
    setOrderId(undefined);
    setOrderDetails(null);
  };

  const handleCancelRequest = () => {
    setLoading(true);

    const confirm = window.confirm(
      `This will cancel order ${orderId} with the pharmacy. Are you sure?`
    );

    setLoading(true);
    confirm &&
      axios({
        method: "DELETE",
        url: `${apiEndpoint}admin/v1/truepill/order/${orderId}`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response: any) => {
          if (response.status === 200) {
            return;
          } else {
            console.log(response);
            throw response.status;
          }
        })
        .then((data) => {
          setLoading(false);
          alert("Order cancelled");
        })
        .then(() => {
          return handleRequest();
        })
        .catch((error) => {
          setLoading(false);
          alert(`Something went wrong ${error}`);
        });
  };

  if (!user || !user.email) {
    navigate("/");
    return null;
  } else {
    return !forecasting ? (
      <div className={"dash"}>
        <Sidebar activeTab="pharmacy" />
        <div className={"reportTools"}>
          {loading && (
            <div className="loadingOverlay">
              <div>Loading...</div>
            </div>
          )}

          {orderDetails ? (
            <div className={"reportCard"}>
              <h3>Order details: {orderDetails?.id}</h3>
              <p>PatientId: {orderDetails?.attributes?.patientId}</p>
              <p>
                Medication:{" "}
                {orderDetails?.attributes?.items[0].medication?.name}
              </p>
              <p>
                Shipping Address:{" "}
                {orderDetails?.attributes?.shippingAddress?.name}
              </p>
              <p>
                {orderDetails?.attributes?.shippingAddress?.line1},
                {orderDetails?.attributes?.shippingAddress?.line2},
                {orderDetails?.attributes?.shippingAddress?.city},
                {orderDetails?.attributes?.shippingAddress?.region},
                {orderDetails?.attributes?.shippingAddress?.postcode}
              </p>
              <p>Status: {orderDetails?.attributes?.status}</p>
              {orderDetails?.attributes?.shippingInformation && (
                <p>
                  Tracking Information:{" "}
                  <a
                    href={
                      orderDetails?.attributes?.shippingInformation
                        ?.trackingUrl ===
                        "https://www.royalmail.com/track-your-item" &&
                      orderDetails?.attributes?.shippingInformation?.trackingId
                        ? `https://www.royalmail.com/track-your-item#/tracking-results/${orderDetails?.attributes?.shippingInformation?.trackingId}`
                        : orderDetails?.attributes?.shippingInformation
                            ?.trackingUrl
                    }
                    target="_blank"
                    rel="noreferrer">
                    {orderDetails?.attributes?.shippingInformation?.carrier},{" "}
                    {orderDetails?.attributes?.shippingInformation?.trackingId}
                  </a>
                </p>
              )}
              {orderDetails?.attributes?.resolution?.type === "REJECTED" &&
                orderDetails?.attributes?.resolution?.reason && (
                  <p>
                    Rejection reason:{" "}
                    {orderDetails?.attributes?.resolution?.reason}
                  </p>
                )}
              <button
                className={"cta cancelUserButton"}
                onClick={handleCancelRequest}>
                Cancel order
              </button>
              <button
                className={"cta"}
                style={{ marginLeft: 8 }}
                onClick={handleClearSearch}>
                Clear search
              </button>
            </div>
          ) : (
            <div className={"reportCard"}>
              <h1>Search for Phlo order</h1>
              <p>please enter the order id of the order e.g. ord_1234567891</p>
              <div style={{ display: "grid", marginBottom: 8 }}>
                <input
                  value={orderId || ""}
                  onChange={(e: { target: { value: any } }) => {
                    setOrderId(e.target.value);
                  }}
                  type={"text"}
                />
              </div>
              <button className={"cta"} onClick={handleRequest}>
                Search
              </button>
            </div>
          )}

          <div className={"reportCard"}>
            <h1>Stock forecasting</h1>
            <p>
              This tool helps predict how many of each medication will be needed
              for upcoming weeks
            </p>
            <button
              className={"cta"}
              onClick={() => {
                setForecasting(true);
              }}>
              Go to forecast
            </button>
          </div>
        </div>
      </div>
    ) : (
      <StockForecast
        goBack={() => {
          setForecasting(false);
        }}
      />
    );
  }
};
