import axios from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import "./globals.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import promiseAccumulator from "../helpers/promiseAccumulator";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Row } from "./PrescriptionComponents/row";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import { FeatureFlags } from "./patient-feature-flags";
import { pharmacyDictionary } from "./PrescriptionComponents/createOrderModal";

export const isStaging = process.env.REACT_APP_ENV !== "production";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
export const apiPaymentsEndpoint = process.env.REACT_APP_PAYMENT_URL;
export const glpApiEndpoint = process.env.REACT_APP_GLP_URL;

interface Props {
  setLoading: (isLoading: boolean) => void;
  initialEmail?: string;
  isHabitualAdmin?: boolean;
}

export const PrescriptionPayments = ({ setLoading, initialEmail }: Props) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);

  const [error, setError] = useState<boolean>(false);
  const [patientQuery, setPatientQuery] = useState<string | null>(
    initialEmail || null
  );
  const [patient, setPatient] = useState<any>(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [defaultPharmacy, setDefaultPharmacy] = useState<{
    value: number;
    label: string;
  }>({ value: -1, label: "None" });

  const handlePatientDetailsSearch = useCallback(
    () => {
      setLoading(true);
      setError(false);
      const requests = [
        () =>
          axios({
            method: "get",
            url: `${apiEndpoint}admin/patient/${patientQuery}/prescriptionHistory`,
            headers: {
              "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
              Authorization: `Bearer ${authToken}`,
              "x-api-key": `${process.env.REACT_APP_APP_API_KEY}`,
            },
          }),
        () =>
          axios({
            method: "get",
            url: `${apiEndpoint}admin/patient/${patientQuery}/featureFlagsV2`,
            headers: {
              "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
              Authorization: `Bearer ${authToken}`,
              "x-api-key": `${process.env.REACT_APP_APP_API_KEY}`,
            },
          }),
      ];
      promiseAccumulator(requests)
        .then(([response1, response2]: any) => {
          if (response1.statusText === "OK") {
            setPatient(response1.data);
            setLoading(false);
          } else {
            throw response1;
          }
          if (response2.statusText === "OK") {
            const defaultPharmacyFlag = response2.data.find(
              (flag: { feature: FeatureFlags; data?: string }) => {
                return flag.feature === FeatureFlags.DEFAULT_PHARMACY;
              }
            );
            if (defaultPharmacyFlag && defaultPharmacyFlag.data) {
              const value = parseInt(defaultPharmacyFlag.data, 10);
              // defaulting to phlo here for now
              setDefaultPharmacy(
                pharmacyDictionary[value] || pharmacyDictionary[0]
              );
            }
          }
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patientQuery, refreshCounter, authToken]
  );
  const handleResetMedicationReviewTask = (medicationReviewId: number) => {
    const confirm = window.confirm(
      `Are you sure you want to reset the medication review task for this user?`
    );

    const requests = [
      () =>
        axios({
          method: "delete",
          url: `${apiEndpoint}admin/patient/${patientQuery}/medicationReview/${medicationReviewId}`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "x-api-key": `${process.env.REACT_APP_APP_API_KEY}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          console.log("Patient medication review task delete:", response1);
          alert("Patient medication review task was reset");
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data: { email: patientQuery },
              note: "Patient's medication review task was reset.",
            });
          }
        })
        .then(() => {
          setRefreshCounter(refreshCounter + 1);
        })
        .catch((error) => {
          console.log(
            `Error resetting ${patientQuery}'s medication review task.`,
            error
          );
        });
  };
  const handleUpdateNewDosage = (
    medicationReviewId: number,
    currentNewDosage: string,
    updatedNewDosage: string
  ) => {
    const confirm = window.confirm(
      `Are you sure you want to update the new dosage for this patient?`
    );

    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${patientQuery}/medicationReview/${medicationReviewId}`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "x-api-key": `${process.env.REACT_APP_APP_API_KEY}`,
            "Content-Type": "application/json",
          },
          data: { newDosage: updatedNewDosage },
        }),
    ];

    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          console.log("Patient's new dosage was updated':", response1);
          alert(`Patient's new dosage was updated to ${updatedNewDosage}`);
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data: { email: patientQuery },
              note: `Patient's new dosage was updated from ${currentNewDosage} to ${updatedNewDosage}.`,
            });
          }
        })
        .then(() => {
          setRefreshCounter(refreshCounter + 1);
        })
        .catch((error) => {
          console.log(`Error updating ${patientQuery}'s new dosage.`, error);
        });
  };

  const handleCancelOrder = (
    pharmacyId: number,
    consultationId: string,
    orderId?: string
  ) => {
    const confirm = window.confirm(
      orderId
        ? `This will cancel order ${orderId} with the pharmacy. Are you sure?`
        : `This will cancel consultation ${consultationId} with the pharmacy. Are you sure?`
    );
    let message: string;
    setLoading(true);
    confirm &&
      axios({
        method: "PATCH",
        url: `${glpApiEndpoint}admin/v1/pharmacy/${pharmacyId}/order/cancel`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
        data: { orderId, consultationId },
      })
        .then((response: any) => {
          if (response.status === 200) {
            message = response.data.message;
            return updatePatientNotes({
              user,
              authToken,
              data: { email: patientQuery },
              note: orderId
                ? `Patient's order (${orderId}) was cancelled with pharmacy ${pharmacyId}.`
                : `Patient's consultation (${consultationId}) was cancelled with pharmacy ${pharmacyId}.`,
            });
          } else {
            console.log(response);
            throw response.status;
          }
        })
        .then((data) => {
          setLoading(false);
          alert(message);
        })
        .then(() => {
          setRefreshCounter(refreshCounter + 1);
        })
        .catch((error) => {
          setLoading(false);
          alert(`Something went wrong ${error}`);
        });
  };

  useEffect(
    () => {
      refreshCounter !== 0 && handlePatientDetailsSearch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshCounter]
  );

  const ErrorMessage = () => {
    return (
      <div>
        <span>{"Looks like there was a break in one of my circuits"}</span>
      </div>
    );
  };

  const PatientsPaymentView = () => {
    if (!patient) return null;
    return (
      <div>
        <div>
          <h3>Default Pharmacy for this patient:</h3>
          <p>{defaultPharmacy.label}</p>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Payment Date</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Has Review</TableCell>
                <TableCell align="center">Has Prescription</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {patient.map((row: any, index: number) => (
                <React.Fragment key={`row-${index}`}>
                  <Row
                    key={index}
                    row={row}
                    email={patientQuery}
                    defaultPharmacyId={defaultPharmacy.value}
                    handleResetTask={() =>
                      handleResetMedicationReviewTask(row.medicationReview.id)
                    }
                    handleUpdateNewDosage={(updatedNewDosage: string) =>
                      handleUpdateNewDosage(
                        row.medicationReview.id,
                        row.medicationReview?.newDosage,
                        updatedNewDosage
                      )
                    }
                    handleCancelOrder={(
                      pharmacyId: number,
                      consultationId: string,
                      orderId?: string
                    ) => handleCancelOrder(pharmacyId, consultationId, orderId)}
                    refresh={() => {
                      setRefreshCounter(refreshCounter + 1);
                    }}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <div className={"managePatients"}>
      {!patient ? (
        <Fragment>
          <div className={"emailInput"}>
            <div className={"emailInputLabel cascade"}>
              <label>{"Please enter patient email:"}</label>
              <span>{"this must be an exact email address"}</span>
            </div>
            <input
              onChange={(e) => setPatientQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handlePatientDetailsSearch();
                }
              }}
            />
            <button className={"cta"} onClick={handlePatientDetailsSearch}>
              {"Get payments"}
            </button>
          </div>
          {!patient && error && <ErrorMessage />}
        </Fragment>
      ) : (
        <Fragment>
          <div className={"viewing"}>
            <div className={"viewingText"}>
              <label>{"You are currently looking at:"}</label>
              <span>{patientQuery}</span>
            </div>
            <button
              className={"cta"}
              onClick={() => {
                setPatient(null);
              }}
            >
              {"Change patient"}
            </button>
            <button
              className={"cta"}
              style={{ marginLeft: 12 }}
              onClick={() => {
                handlePatientDetailsSearch();
              }}
            >
              {"🔄"}
            </button>
          </div>
          {!!patient && <PatientsPaymentView />}
          {!patient && error && <ErrorMessage />}
        </Fragment>
      )}
    </div>
  );
};
