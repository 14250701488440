import React from "react";
import "./globals.css";

export const NotFound = () => {
  return (
    <div className={"notFound"}>
      {'Nothing to see here...'}
    </div>
  );
};
