import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import promiseAccumulator from "../helpers/promiseAccumulator";
import axios from "axios";
import { RechargeChargePaidWebhookPayload } from "../types/testing-tools";
// export const apiEndpoint = process.env.REACT_APP_PAYMENT_URL;
export const glpApiEndpoint = process.env.REACT_APP_GLP_URL;
export const TestingTools = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [patientDetails, setPatientDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    productName: "Wegovy",
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
  });

  const handleRequest = () => {
    setLoading(true);
    // const data = {
    //   id: 820982911946154500,
    //   admin_graphql_api_id: "gid://shopify/Order/820982911946154508",
    //   created_at: "2021-12-31T19:00:00-05:00",
    //   currency: "GBP",
    //   email: patientDetails.email,
    //   phone: patientDetails.phone,
    //   total_price: "199.00",
    //   customer: {
    //     id: 115310627314723950,
    //     email: patientDetails.email,
    //     first_name: patientDetails.firstName,
    //     last_name: patientDetails.lastName,
    //     phone: patientDetails.phone,
    //     currency: "GBP",
    //     admin_graphql_api_id: "gid://shopify/Customer/115310627314723954",
    //   },
    //   line_items: [
    //     {
    //       id: 866550311766439000,
    //       admin_graphql_api_id: "gid://shopify/LineItem/866550311766439020",
    //       name: patientDetails.productName,
    //       price: "199.00",
    //       product_id:
    //         patientDetails.productName === "Ozempic"
    //           ? "7456071123133"
    //           : "7456071450813",
    //       title: patientDetails.productName,
    //     },
    //   ],
    //   shipping_address: {
    //     first_name: patientDetails.firstName,
    //     address1: patientDetails.address1,
    //     phone: patientDetails.phone,
    //     city: patientDetails.city,
    //     zip: patientDetails.postcode,
    //     province: patientDetails.county,
    //     country: "United Kingdom",
    //     last_name: patientDetails.lastName,
    //     address2: patientDetails.address2,
    //   },
    // };

    const mockedRechargeData: RechargeChargePaidWebhookPayload = {
      charge: {
        id: 12345,
        currency: "GBP",
        customer: {
          id: 12345,
          email: patientDetails.email,
        },
        external_order_id: { ecommerce: "SHOPIFY_ORDER_12345" },
        line_items: [
          {
            external_product_id: {
              ecommerce:
                patientDetails.productName === "Wegovy"
                  ? "7760591257790"
                  : patientDetails.productName === "Ozempic"
                  ? "7601236574398"
                  : patientDetails.productName === "Mounjaro"
                  ? "7881889317054"
                  : "7586699870398",
            },
          },
        ],
        orders_count: 1,
        processed_at: "2021-08-14T00:00:00",
        shipping_address: {
          address1: patientDetails.address1,
          address2: patientDetails.address2,
          city: patientDetails.city,
          country: "UK",
          first_name: patientDetails.firstName,
          last_name: patientDetails.lastName,
          phone: patientDetails.phone,
          province: patientDetails.county,
          zip: patientDetails.postcode,
        },
        total_price: patientDetails.productName === "Ozempic" ? "319" : "199",
        type: "checkout",
        status: "success",
      },
    };

    // const requests = [
    //   () =>
    //     axios({
    //       method: "POST",
    //       url: `${apiEndpoint}api/v1/order/shopify_webhook_mock`,
    //       headers: {
    //         "admin-key": `${process.env.REACT_APP_PAYMENT_API_KEY}`,
    //         Authorization: `Bearer ${authToken}`,
    //       },
    //       data,
    //     }),
    // ];
    const requests = [
      () =>
        axios({
          method: "POST",
          url: `${glpApiEndpoint}api/v1/webhook/admin/charge/created`,
          headers: {
            "admin-key": `${process.env.REACT_APP_GLP_API_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
          data: mockedRechargeData,
        }),
    ];
    promiseAccumulator(requests)
      .then(async ([response1]: any) => {
        if (response1.data) {
          return response1.data;
        }
      })
      .then((data) => {
        setLoading(false);
        alert("Thanks for your purchase - now go download the app!");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (!user || !user.email) {
    navigate("/");
    return null;
  } else {
    return (
      <div className={"dash"}>
        <Sidebar activeTab="testing" />
        <div className={"reportTools"}>
          {loading && (
            <div className="loadingOverlay">
              <div>Loading...</div>
            </div>
          )}

          <div className={"reportCard"}>
            <h1>Mock purchase</h1>
            <p>
              This will fake a shopify purchase so that you can trigger the app
              account creation and necesary cio workflows
            </p>
            <div
              style={{
                margin: 5,
                display: "flex",
                flexDirection: "column",
                maxWidth: "40%",
              }}
            >
              <div style={{ display: "grid" }}>
                <label style={{ flex: 1 }}>First Name</label>
                <input
                  value={patientDetails.firstName}
                  onChange={(e: { target: { value: any } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      firstName: e.target.value,
                    });
                  }}
                  type={"text"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Last Name</label>
                <input
                  value={patientDetails.lastName}
                  onChange={(e: { target: { value: any } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      lastName: e.target.value,
                    });
                  }}
                  type={"text"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Email</label>
                <input
                  value={patientDetails.email}
                  onChange={(e: { target: { value: string } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      email: e.target.value.trim(),
                    });
                  }}
                  type={"email"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Phone number</label>
                <input
                  style={
                    patientDetails.phone.length >= 9 &&
                    patientDetails.phone.length < 11
                      ? { border: "1px red solid" }
                      : {}
                  }
                  placeholder="07777 777 777"
                  value={patientDetails.phone}
                  onChange={(e: { target: { value: string } }) =>
                    setPatientDetails({
                      ...patientDetails,
                      phone: e.target.value.trim(),
                    })
                  }
                  type={"tel"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Address line 1</label>
                <input
                  value={patientDetails.address1}
                  onChange={(e: { target: { value: any } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      address1: e.target.value,
                    });
                  }}
                  type={"text"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Address line 2</label>
                <input
                  value={patientDetails.address2}
                  onChange={(e: { target: { value: any } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      address2: e.target.value,
                    });
                  }}
                  type={"text"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>City</label>
                <input
                  value={patientDetails.city}
                  onChange={(e: { target: { value: any } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      city: e.target.value,
                    });
                  }}
                  type={"text"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>County</label>
                <input
                  value={patientDetails.county}
                  onChange={(e: { target: { value: any } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      county: e.target.value,
                    });
                  }}
                  type={"text"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Postcode</label>
                <input
                  value={patientDetails.postcode}
                  onChange={(e: { target: { value: any } }) => {
                    setPatientDetails({
                      ...patientDetails,
                      postcode: e.target.value,
                    });
                  }}
                  type={"text"}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Product</label>
                <select
                  onChange={(e) => {
                    setPatientDetails({
                      ...patientDetails,
                      productName: e.target.value,
                    });
                  }}
                >
                  <option
                    selected={patientDetails.productName === "Wegovy"}
                    value={"Wegovy"}
                  >
                    Wegovy
                  </option>
                  <option
                    selected={patientDetails.productName === "Mounjaro"}
                    value={"Mounjaro"}
                  >
                    Mounjaro
                  </option>
                  <option
                    selected={patientDetails.productName === "Ozempic"}
                    value={"Ozempic"}
                  >
                    Ozempic
                  </option>
                  <option
                    selected={patientDetails.productName === "Rybelsus"}
                    value={"Rybelsus"}
                  >
                    Rybelsus
                  </option>
                </select>
              </div>
            </div>
            <button className={"cta"} onClick={handleRequest}>
              Purchase!
            </button>
          </div>
        </div>
      </div>
    );
  }
};
