import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "../styles/ag-grid.css";
import "../styles/ag-theme-habitual.css";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import axios from "axios";
import { isStaging } from "./manage-patients";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const MealOrdersView = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [activeTab, setActiveTab] = useState("upcomingPayments");
  const [upcomingPaymentData, setUpcomingPaymentData] = useState<
    {
      hubspotId: string;
      name: string;
      email: string;
      paymentPlan: string;
      paymentDate: string;
      orderStatus: string;
      featureOn: boolean;
    }[]
  >([]);
  const [allMealOrdersData, setAllMealOrdersData] = useState<
    {
      hubspotId: string;
      name: string;
      email: string;
      paymentPlan: string;
      paymentDate: string;
      orderDate: string;
      orderStatus: string;
      featureOn: boolean;
    }[]
  >();

  const [columnDefs] = useState([
    { headerName: "ID", field: "hubspotId", filter: true, resizable: true },
    { field: "name", filter: true, resizable: true },
    { field: "email", filter: true, resizable: true },
    { field: "paymentPlan", filter: true, resizable: true },
    { field: "paymentDate", filter: true, resizable: true },
    {
      field: "orderStatus",
      filter: true,
      cellStyle: (params: any) => {
        if (params.value === "Not done") {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value === "ready") {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
  ]);
  const [columnDefsAllOrders] = useState([
    { headerName: "ID", field: "hubspotId", filter: true, resizable: true },
    { field: "name", filter: true, resizable: true },
    { field: "email", filter: true, resizable: true },
    { field: "orderDate", filter: true, resizable: true },
    { field: "dispatchDate", filter: true, resizable: true },
    { field: "orderType", filter: true, resizable: true },
    { field: "paymentPlan", filter: true, resizable: true },
    { field: "paymentDate", filter: true, resizable: true },
    {
      field: "orderStatus",
      filter: true,
      cellStyle: (params: any) => {
        if (params.value === "Not done") {
          return { color: "#E16756", backgroundColor: "#f7f8fd" };
        } else if (params.value === "ready") {
          return { color: "#2c4a43", backgroundColor: "#b2f1dd" };
        }
        return null;
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const gridRef: any = useRef();

  const cellClickedListener = useCallback(
    (event) => {
      if (event.colDef.field === "hubspotId") {
        const id = event.value;
        const url = `https://fly.customer.io/env/${
          isStaging ? "113066" : "113271"
        }/people/${id}`;
        window.open(url, "_blank");
      } else if (event.colDef.field === "name") {
        navigate(`/dashboard?query=${event.data.email}`);
      } else if (event.colDef.field === "email") {
        navigator.clipboard.writeText(event.value);
      }
    },
    [navigate]
  );

  useEffect(() => {
    setLoading(true);
    const getUpcomingPaymentData = async () => {
      try {
        let response1 = await axios({
          method: "get",
          url: `${apiEndpoint}admin/mealOrders`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response1.data) {
          setUpcomingPaymentData(response1.data.patients);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    getUpcomingPaymentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    const getAllMealOrderData = async () => {
      try {
        let response1 = await axios({
          method: "get",
          url: `${apiEndpoint}admin/allPatientMealOrders`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response1.data) {
          const dataToUse: any[] = [];
          response1.data.patients.forEach((patient: any) => {
            if (patient.Orders.length > 0) {
              return patient.Orders.forEach((order: any) => {
                dataToUse.push({ ...order, ...patient });
              });
            } else {
              return null;
            }
          });
          setAllMealOrdersData(dataToUse);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    getAllMealOrderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user || !user.email) {
      navigate("/");
    }
  }, [navigate, user]);

  if (user && user.email) {
    return (
      <div className={"dash"}>
        <Sidebar activeTab="mealOrders" />
        <div>
          {loading ||
            (!upcomingPaymentData && (
              <div className="loadingOverlay">
                <div>Loading...</div>
              </div>
            ))}
          <div
            className="viewing"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <button
              onClick={() => {
                setActiveTab("upcomingPayments");
              }}
              className={`sidebarNavButton ${
                activeTab === "upcomingPayments" && "activeNavButton"
              }`}
              style={{ marginRight: 10, marginLeft: 10 }}
            >
              Upcoming Payments
            </button>
            <button
              onClick={() => {
                setActiveTab("allOrders");
              }}
              className={`sidebarNavButton ${
                activeTab === "allOrders" && "activeNavButton"
              }`}
              style={{ marginRight: 10, marginLeft: 10 }}
            >
              All Patient Orders
            </button>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ width: "90%", height: "80%", margin: 24 }}
          >
            {activeTab === "upcomingPayments" && (
              <>
                <AgGridReact
                  ref={gridRef}
                  rowData={upcomingPaymentData} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                />
                {upcomingPaymentData && (
                  <div>Number of patients: {upcomingPaymentData.length}</div>
                )}
                {upcomingPaymentData && (
                  <div>
                    Number of orders left to place:{" "}
                    {
                      upcomingPaymentData.filter((m) => {
                        return m.orderStatus === "Not done";
                      }).length
                    }
                  </div>
                )}
              </>
            )}
            {activeTab === "allOrders" && (
              <>
                <AgGridReact
                  ref={gridRef}
                  rowData={allMealOrdersData} // Row Data for Rows
                  columnDefs={columnDefsAllOrders} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
